<template>
  <layout
    :style="`background: ${$theme.background}`"
    :title="$route.name"
    :options="options"
  >
    <div class="expande-horizontal wrap" style="background: #e2e2e2;"></div>
  </layout>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: ["id"],
  data() {
    return {
      options: [
        {
          icon: "mdi-update",
          nome: "Atualizar",
          action: () => this.listar_caixas()
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["get_caixa", "getLoggedUser"])
  },
  methods: {
    ...mapActions(["abre_modal_view_caixa", "fetch_caixa"]),
    imprimir() {
      this.card = false;
      this.$refs.html2Pdf.generatePdf();
    }
  },
  created() {
    this.get_caixa(this.id);
  }
};
</script>
